<template>
  <div class="component-transactions-tags">

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper page-wrapper bg-light">
      <!-- HEADER -->
      <b-row class="breadcrumb-wrapper" no-gutters>
        <b-col no-gutters cols="12">
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
            <h1 class="text-left">{{ $t(translationPath + 'heading')}}</h1>
          </b-col>
        </b-col>
      </b-row>

      <!-- BODY -->
      <b-container class="main-content-wrapper pt-0">
        <div class="bordered-table mt-3">
          <b-row no-gutters>
            <b-col cols="12" md="6">
              <h2>{{ $t(translationPath + 'heading') }}</h2>
            </b-col>
            <b-col cols="12" md="6">
              <b-link class="btn btn-xs btn-secondary float-right mb-2 mb-md-0" @click.prevent="onBtnAddTagClick">
                <i class="flaticon stroke plus"></i>
                {{ $t(translationPath + "buttons.add_tag") }}
              </b-link>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="col-lg-8">
              <div v-html="$t(translationPath + 'description')"></div>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <tag-edit-quick-form
                v-if="isQuickEditFormShown"
                ref="QuickForm"
                :existed-tags="tagTitles"
                :edit-item="editItem"
                @cancel="isQuickEditFormShown = false"
                @saved="onTagSaved"
                @failed="onTagSaveFailed"
              />
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-table
                :items="tags"
                :fields="tableFields"
                :busy="!isLoaded"
                :empty-text="$t(translationPath + 'table.no_data')"
                stacked="md"
                class="spirecta-simple-table mb-0"
                responsive
                striped
                show-empty
              >
                <template v-slot:table-busy>
                  <loader />
                </template>
                <template v-slot:cell(is_active)="row">
                  <template v-if="row.item.is_active">{{ $t('common.yes') }}</template>
                  <template v-else>{{ $t('common.no') }}</template>
                  <!--<b-form-select
                    v-model="row.item.is_active"
                    :options="[{ value: 1, text: $t('common.yes') }, { value: 0, text: $t('common.no') }]"
                    @change="onChangeActiveClick(row.item)"
                  />-->
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    @click="onEditClick(row.item)"
                    :title="$t('common.edit')"
                    class="plain-btn action-button mr-2"
                  >
                    <i class="flaticon solid edit-3 text-primary"></i
                  ></b-button>
                  <b-button
                    @click="onDeleteClick(row.item)"
                    :title="$t('common.delete')"
                    class="plain-btn action-button"
                  >
                    <i class="flaticon solid trash-3 text-danger"></i
                  ></b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-container>
    <!-- END PAGE WRAPPER -->

    <delete-modal
      @on-delete-cancel="onDeleteCancel"
      @on-delete-confirm="onDeleteConfirm"
      ref="DeleteTagModal"
      :heading="$t(translationPath + 'modal_delete.title')"
      :message="$t(translationPath + 'modal_delete.text', { title: itemToDelete ? itemToDelete.title : '' })"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import Loader from '@/components/common/Loader'
import DeleteModal from '@/components/modals/DeleteModal'
import TagEditQuickForm from './TagEditQuickForm'

export default {
  name: 'TransactionsTags',
  mixins: [titleMixins],
  components: { Loader, DeleteModal, TagEditQuickForm },
  data () {
    return {
      tags: [],
      translationPath: 'transactions.tags.',
      isLoaded: false,
      itemToDelete: null,
      isQuickEditFormShown: false,
      editItem: null,
      useWizard: 0
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      const aBreadcrumb = [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    tableFields () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table.th.title'), sortable: true },
        { key: 'description', label: this.$t(this.translationPath + 'table.th.description'), sortable: true },
        { key: 'is_active', label: this.$t(this.translationPath + 'table.th.is_active'), sortable: true },
        { key: 'actions', label: '', sortable: false }
      ]
    },
    tagTitles () {
      const titles = []
      this.tags.map(tag => {
        titles.push(tag.title)
      })
      return titles
    }
  },
  methods: {
    async loadData () {
      return new Promise((resolve, reject) => {
        this.isLoaded = false
        this.tags = []
        axios.get(`${process.env.VUE_APP_ROOT_API}/tags`)
          .then(response => {
            this.tags = response.data.data
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
          .finally(() => {
            this.isLoaded = true
          })
      })
    },
    onChangeActiveClick (item) {
      axios.put(`${process.env.VUE_APP_ROOT_API}/tags/${item.id}`, { title: item.title, is_active: item.is_active })
        .then(response => {
          const toastTitle = this.translationPath + 'activation_toast.success.' + (item.is_active ? 'activation.' : 'deactivation.') + 'title'
          const toastText = this.translationPath + 'activation_toast.success.' + (item.is_active ? 'activation.' : 'deactivation.') + 'text'
          this.$bvToast.toast(this.$t(toastText, { title: item.title }), {
            title: this.$t(toastTitle),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
          const toastTitle = this.translationPath + 'activation_toast.fail.' + (item.is_active ? 'activation.' : 'deactivation') + 'title'
          const toastText = this.translationPath + 'activation_toast.fail.' + (item.is_active ? 'activation.' : 'deactivation') + 'text'
          this.$bvToast.toast(this.$t(toastText, { title: item.title }), {
            title: this.$t(toastTitle),
            variant: 'danger',
            solid: true
          })
        })
    },
    onEditClick (item) {
      if (this.useWizard) {
        this.$router.push({ name: 'transaction-tags-edit', params: { tag_id: item.id } })
      } else {
        this.editItem = item
        this.isQuickEditFormShown = true
        window.scrollTo(0, 0)
        process.nextTick(() => {
          this.$refs.QuickForm.$focus()
        })
      }
    },
    onDeleteClick (item) {
      this.itemToDelete = item
      this.$refs.DeleteTagModal.show()
    },
    onDeleteCancel () {
      this.itemToDelete = null
    },
    onDeleteConfirm () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/tags/${this.itemToDelete.id}`)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'modal_delete.toast.success', { title: this.itemToDelete.title }), {
            title: this.$t(this.translationPath + 'modal_delete.toast.title'),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'modal_delete.toast.fail', { title: this.itemToDelete.title }), {
            title: this.$t(this.translationPath + 'modal_delete.toast.title'),
            variant: 'danger',
            solid: true
          })
        })
        .finally(() => {
          this.tags = this.tags.filter(tag => tag.id !== this.itemToDelete.id)
          this.itemToDelete = null
        })
    },
    onBtnAddTagClick () {
      if (this.useWizard) {
        this.$router.push({ name: 'transaction-tags-create' })
      } else {
        this.editItem = null
        this.isQuickEditFormShown = true
        process.nextTick(() => {
          this.$refs.QuickForm.$focus()
        })
      }
    },
    onTagSaved () {
      this.editItem = null
      this.isQuickEditFormShown = false
      this.loadData()
      this.$bvToast.toast(this.$t(this.translationPath + 'quick_form_toast.success'), {
        title: this.$t(this.translationPath + 'quick_form_toast.title'),
        variant: 'success',
        solid: true
      })
    },
    onTagSaveFailed () {
      this.$bvToast.toast(this.$t(this.translationPath + 'quick_form_toast.fail'), {
        title: this.$t(this.translationPath + 'quick_form_toast.title'),
        variant: 'danger',
        solid: true
      })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-transactions-tags{
  width: 100%;

  .page-wrapper{
    min-height: 100%;
    padding-left:35px;
    padding-right:35px;
  }

  table {
    .action-button {
      padding: 0;
      border: none;
      line-height: normal;
      i {
        margin: 0;
        font-size: 13px;
      }
      &:hover {
        background: transparent;
      }
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background: transparent;
        box-shadow: none !important;
      }
    }
  }
}
</style>
