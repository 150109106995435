<template>
  <div class="transaction-tags-quick-edit-form">
    <b-row no-gutters>
      <b-col class="mt-3">
        <h3 v-if="editItem === null">{{ $t(translationPath + 'create_tag') }}</h3>
        <h3 v-else>{{ $t(translationPath + 'edit_tag', { title: editItem.title }) }}</h3>
      </b-col>
    </b-row>
    <b-form>
      <b-row no-gutters>
        <b-col>
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-for="title"
            label-cols-lg="3"
            label-align-lg="right"
            required
            :invalid-feedback="invalidTitleFeedback"
            :label="$t(translationPath + 'step1.title_label')"
          >
            <template slot="description">{{ $t(translationPath + 'step1.title_help_text') }}</template>
            <b-form-input id="title" class="rounded-pill"
              ref="Title"
              :placeholder="$t(translationPath+'step1.title_placeholder')"
              :state="$v.title.$dirty ? !$v.title.$error : null"
              v-model="title"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-for="description"
            label-cols-lg="3"
            label-align-lg="right"
            required
            :invalid-feedback="invalidDescriptionFeedback"
            :label="$t(translationPath + 'step1.description_label')"
          >
            <template slot="description">{{ $t(translationPath + 'step1.description_help_text') }}</template>
            <b-form-textarea id="description"
              :state="$v.description.$dirty ? !$v.description.$error : null"
              v-model="description"
            />
          </b-form-group>
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-for="is-active"
            label-cols-lg="3"
            label-align-lg="right"
            required
            :label="$t(translationPath + 'step1.is_active_label')"
          >
            <template slot="description">{{ $t(translationPath + 'step1.is_active_help_text') }}</template>
            <b-form-select
              id="is-active"
              v-model="isActive"
              :options="[{ text: $t('common.yes'), value: 1 }, { text: $t('common.no'), value: 0 }]"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="text-right">
          <b-link class="btn btn-outline-secondary mr-2 mb-2" @click.prevent="onClickCancel">
            {{ $t("common.cancel") }}
          </b-link>
          <b-link class="btn btn-primary mb-2" @click.prevent="onClickSave">
            {{ $t("transactions.tags.buttons.quick_save_tag") }}
          </b-link>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'

const isTitleUnique = getter => function () {
  const editedTitlePosition = this.editItem !== null ? this.existedTags.indexOf(this.editItem.title) : -2
  const newTitlePosition = this.existedTags.indexOf(this.title)
  if (this.title && newTitlePosition !== -1 && newTitlePosition !== editedTitlePosition) {
    return false
  }
  return true
}

export default {
  name: 'TagEditQuickForm',
  props: {
    existedTags: {
      type: Array,
      default: () => { return [] }
    },
    editItem: {
      type: Object
    }
  },
  data () {
    return {
      translationPath: 'transactions.tags.wizard.',
      title: '',
      description: '',
      isActive: 1,
      isInProcess: false
    }
  },
  validations: {
    title: { required, minLength: minLength(2), maxLength: maxLength(64), isTitleUnique: isTitleUnique() },
    description: { maxLength: maxLength(255) },
    form: ['title', 'description']
  },
  computed: {
    invalidTitleFeedback () {
      if (this.$v.title.required === false) { return this.$t(this.translationPath + 'step1.errors.title.required') }
      if (this.$v.title.minLength === false) { return this.$t(this.translationPath + 'step1.errors.title.min_length') }
      if (this.$v.title.maxLength === false) { return this.$t(this.translationPath + 'step1.errors.title.max_length') }
      if (this.$v.title.isTitleUnique === false) { return this.$t(this.translationPath + 'step1.errors.title.not_unique') }
      return ''
    },
    invalidDescriptionFeedback () {
      if (this.$v.description.maxLength === false) { return this.$t(this.translationPath + 'step1.errors.description.max_length') }
      return ''
    }
  },
  methods: {
    onClickCancel () {
      this.title = ''
      this.description = ''
      this.isActive = 1
      this.$v.form.$reset()
      this.$emit('cancel')
    },
    onClickSave () {
      if (this.isInProcess) {
        return false
      }
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.isInProcess = true

      const data = { title: this.title, is_active: this.isActive }
      if (this.description) {
        data.description = this.description
      }

      const sApiMethod = this.editItem !== null ? 'PUT' : 'POST'
      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/tags' + (this.editItem !== null ? `/${this.editItem.id}` : '')

      axios({
        method: sApiMethod,
        url: sApiEndpoint,
        data: data
      })
        .then(response => {
          this.title = ''
          this.description = ''
          this.$v.form.$reset()
          this.$emit('saved', response.data.data)
        })
        .catch(err => {
          this.$emit('failed', err)
          console.error(err)
        })
        .finally(() => {
          this.isInProcess = false
        })
    },
    setupEditItem () {
      this.title = this.editItem.title
      this.description = this.editItem.description
      this.isActive = this.editItem.is_active
    },
    $focus () {
      this.$refs.Title.focus()
    }
  },
  mounted () {
    if (this.editItem !== null) {
      this.setupEditItem()
    }
  },
  watch: {
    editItem: {
      deep: true,
      handler (newItem) {
        if (newItem !== null) {
          this.setupEditItem()
        } else {
          this.title = ''
          this.description = ''
          this.isActive = 1
        }
      }
    }
  }
}
</script>
